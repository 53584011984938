<template>
  <div class="home">
    <div :style="'height:' + (maxHeight - 90) + 'px'">
      <q-carousel
        animated
        v-model="slide"
        autoplay
        navigation
        infinite
        :height="maxHeight + 'px'"
        class="bg-light-blue-2 text-white shadow-1 rounded-borders"
        style="top: -90px;"
      >
        <q-carousel-slide
          v-for="i in 5"
          :key="i"
          :name="'ke-' + i"
          :img-src="'./img/carousel/gb-' + i + '.jpg'"
          class="column no-wrap flex-center"
        >
          <div class="q-mt-xl q-pt-xl">
            <div class="text-h3 text-center">
              {{ jumbotron_text[i - 1].title }}
            </div>
            <div class="q-mt-md text-center text-h6">
              {{ jumbotron_text[i - 1].sub }}
            </div>
          </div>
        </q-carousel-slide>
      </q-carousel>
    </div>
    <div class="bg-white row  justify-evenly" :class="maxWidth > 500 ? 'q-pa-xl' : 'q-pa-sm wrap'">
      <q-card class="no-shadow" :class="maxWidth > 500 ? 'col-6 q-pa-md' : 'full-width'" :style="'width:' + maxWidth">
        <q-card-section class="text-h4">
          Mengenai SMP AFBS
        </q-card-section>
        <q-card-section class="text-subtitle1" style="line-height: 2em;">
          SMP Al Furqon Boarding School (AFBS) adalah sekolah Menengah Pertama yang beroperasi mulai tanggal 16 Juli
          2018 di bawah naungan Yayasan DAR Al Furqon Al Hakim. Adapun Pendirian SMP AFBS bertujuan untuk ikut serta
          mensukseskan program Pendidikan Nasional yang berfungsi mengembangkan kemampuan dan membentuk watak serta
          peradaban bangsa yang bermartabat dalam rangka mencerdaskan kehidupan bangsa
        </q-card-section>
      </q-card>
      <q-card class="no-shadow" v-show="maxWidth > 500">
        <q-card-section>
          <img src="@/assets/img/Logo4.png" alt="logo_big" style="width: 300px; width: 300px;" />
        </q-card-section>
      </q-card>
    </div>
    <div class="bg-yellow-14 custom-bg-1 q-py-xl relative-position">
      <div class="q-pt-xl"></div>
      <div class="text-h4 text-center q-pt-xl q-pb-md text-white">Mewujudkan 3 Sukses Generus</div>
      <div class="row justify-evenly q-pa-xl">
        <div class="q-ma-md">
          <img :src="'./img/home-1.jpg'" alt="home-1" class="home-img" />
          <div class="text-h6 text-center text-weight-bold">Faham Agama</div>
        </div>
        <div class="q-ma-md">
          <img :src="'./img/home-2.jpg'" alt="home-2" class="home-img" />
          <div class="text-h6 text-center text-weight-bold">Akhlakul Karimah</div>
        </div>
        <div class="q-ma-md">
          <img :src="'./img/home-3.jpg'" alt="home-3" class="home-img" />
          <div class="text-h6 text-center text-weight-bold">Mandiri</div>
        </div>
      </div>
      <svg viewBox="0 0 100 100" preserveAspectRatio="none" class="svg1">
        <polygon class="svg--sm" fill="white" points="0,0 0,100 50,0 100,100 100,0" />
      </svg>
    </div>
    <div
      class="row justify-center q-py-xl "
      style=""
    >
      <div
        class="q-pa-sm"
        :style="maxWidth > 500 ? 'width:450px;' : 'width:' + maxWidth"
        style=" border: 1px solid silver; border-radius: 10px;"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3966.5405708780872!2d106.615473!3d-6.192171!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb6552d4ab9f4c964!2sSMP%20AL%20FURQON%20BOARDING%20SCHOOL!5e0!3m2!1sid!2sid!4v1605250604455!5m2!1sid!2sid"
          height="350"
          width="100%"
          frameborder="10"
          style="border:0;"
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
      <q-card
        :class="maxWidth > 500 ? 'q-ml-md q-pl-lg' : ''"
        class="no-shadow text-subtitle1"
        style="min-width:300px;"
      >
        <q-card-section class="text-h4   text-weight-bold">
          Kontak
        </q-card-section>
        <q-card-section horizontal>
          <q-card-section>
            <q-icon name="maps" color="light-blue-5" size="lg" />
          </q-card-section>
          <q-card-section class="text-wrap">
            Jl. Untung Suropati No.13, <br />
            Cimone Jaya, Kec. Karawaci, <br />
            Kota Tangerang, Banten 15114
          </q-card-section>
        </q-card-section>
        <q-card-section horizontal>
          <q-card-section>
            <q-icon name="mail" color="light-blue-5" size="lg" />
          </q-card-section>
          <q-card-section>
            smpafbs@gmail.com
          </q-card-section>
        </q-card-section>
        <q-card-section horizontal>
          <q-card-section>
            <q-icon name="phone" color="light-blue-5" size="lg" />
          </q-card-section>
          <q-card-section>
            (021) 55784542
          </q-card-section>
        </q-card-section>
      </q-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      slide: "ke-1",
      jumbotron_text: [
        {
          title: "Selamat Datang",
          sub: "Website Resmi & PPDB Online SMP Al Furqon Boarding School",
        },
        {
          title: "3 Sukses Generus",
          sub: "Memiliki Kefahaman Agama, Ber-Akhlaqul Karimah, Mandiri",
        },
        {
          title: "6 Tabiat Luhur",
          sub: "Rukun | Kompak | Kerja sama yang baik | Jujur | Amanah |  Kerja Keras | Berhemat",
        },
        {
          title: "3 Sukses Generus",
          sub: "Memiliki Kefahaman Agama, Ber-Akhlaqul Karimah, Mandiri",
        },
        {
          title: "6 Tabiat Luhur",
          sub: "Rukun | Kompak | Kerja sama yang baik | Jujur | Amanah |  Kerja Keras | Berhemat",
        },
      ],
    };
  },
  mounted() {
    var date = "03/11/2014";
    var newdate = date
      .split("/")
      .reverse()
      .join("-");
    // console.log(newdate);
  },
  methods: {},
  computed: {
    maxHeight() {
      return this.$q.screen.height;
    },
    maxWidth() {
      return this.$q.screen.width;
    },
  },
};
</script>
<style lang="scss" scoped>
.home-img {
  width: 300px;
  object-fit: cover;
  border-radius: 10%;
  border: 6px solid white;
}

.svg1 {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100px;
}
.svg2 {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
}
.custom-bg-1 {
  //   background-image: url("/img/backgorund1.png") !important;
  //   background-repeat: no-repeat !important;
  //   // background-attachment: ;
  //   background-size: 200%;
  //   background-position: center;
  //   background-blend-mode: multiply;;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import './registerServiceWorker'
import "./quasar";
import axios from "axios";

import VueGallery from "vue-gallery";
import VueLazyload from "vue-lazyload";

Vue.prototype.$http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
});

const moment = require("moment");
require("moment/locale/id");

Vue.use(require("vue-moment"), {
  moment,
});

Vue.component("VGallery", VueGallery);
Vue.use(VueLazyload);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
